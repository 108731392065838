import React, { useEffect, useState } from 'react'
import { useNavigate} from 'react-router-dom';
import { BsBookmarkDash } from "react-icons/bs";
import { BsBookmarkDashFill } from "react-icons/bs";
import axios from 'axios';
import { bookMark, Configuration, degree, employment, jobList, teamList, organization, skill } from '../Api/Url';
import ExperienceJson from '../components/Experience';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Select from 'react-select';

const JobsFilter = () => {
    const config = Configuration();
    const applyJobNav = useNavigate();
  
    const [employmentList, setEmploymentList] = useState([]);
    const [selectedEmpId, setSelectedEmpId] = useState([]);
    const [degreeList, setDegreeList] = useState([]);
    const [selectedDegreeId, setSelectedDegreeId] = useState([]);
    const [skillList, setSkillList] = useState([]);
    const [selectedSkillList, setSelectedSkillList] = useState([]);
    const [organ, setOrgan] = useState([]);
    const [selectedOrgan, setSelectedOrgan] = useState([]);
    const [experience, setExperience] = useState([]);
    const [selectExp, setSelectExp] = useState(null);
    const [jobFilterList, setJobFilterList] = useState([]);
    const [salaryLevel, setSalaryLevel] = useState({min: 10000, max: 100000});
    // const [salaryLevel, setSalaryLevel] = useState({ min: 10000, max: 100000 });
    const [bookmarkedItems, setBookmarkedItems] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(6);
    const [bookMarkCont, setBookMarkCont] = useState('');
    const [selectedJobId, setSelectedJobId] = useState(null);
    const [jobLists, setJobLists] = useState('');
    const [searchJob, setSearchJob] = useState('');
    const [teamName, setTeamName] = useState('')

    const [selectedExperience, setSelectedExperience] = useState(null);
  
    const experienceOptions = ExperienceJson.data.map((exp) => ({
      value: exp.id,
      label: exp.Experience,
    }));
  
    const handleRadioChange = (id) => {
      setSelectExp(id);
      const selectedOption = experienceOptions.find((option) => option.value === id);
      setSelectedExperience(selectedOption);
    };
  
    const handleSelectChange = (selectedOption) => {
      setSelectedExperience(selectedOption);
      setSelectExp(selectedOption.value);
    };

    useEffect(()=>{
        setExperience(ExperienceJson.data);
    }, [])

    const handleChange = (event, newValue) => {
        setSalaryLevel({
          min: newValue[0],
          max: newValue[1],
        });
      };
      

    useEffect(() => {
        axios.get(employment(), config)
            .then(res => setEmploymentList(res.data.data))
            .catch(err => console.log(err));
            
    }, []);

    const handleEmpClick = (id) => {
        setSelectedEmpId(prevSelected =>
            prevSelected.includes(id)
                ? prevSelected.filter(selectId => selectId !== id)
                : [...prevSelected, id]
        );
    };

    useEffect(() => {
        axios.get(degree(), config)
            .then(res => setDegreeList(res.data.data))
            .catch(err => console.log(err));
    }, []);

    const handleDegreeClick = (id) => {
        setSelectedDegreeId(prevSelected =>
            prevSelected.includes(id)
                ? prevSelected.filter(selectId => selectId !== id)
                : [...prevSelected, id]
        );
    };

    useEffect(() => {
        axios.get(skill(), config)
            .then(res => setSkillList(res.data.data))
            .catch(err => console.log(err));
    }, []);

    const handleSkillClick = (id) => {
        setSelectedSkillList(prevSelected =>
            prevSelected.includes(id)
                ? prevSelected.filter(selectId => selectId !== id)
                : [...prevSelected, id]
        );
    };

    useEffect(() => {
        axios.get(organization(), config)
            .then(res => setOrgan(res.data.data))
            .catch(err => console.log(err));
    }, []);

    const handleOrganClick = (id) => {
        setSelectedOrgan(prevSelected =>
            prevSelected.includes(id)
                ? prevSelected.filter(selectId => selectId !== id)
                : [...prevSelected, id]
        );
    };    

    const reloadFilteredJobList = () => {
        const params = {};
    
        if (selectedEmpId.length > 0) params.job_type_ids = selectedEmpId;
        if (selectedDegreeId.length > 0) params.education_ids = selectedDegreeId;
        if (selectedSkillList.length > 0) params.skill_ids = selectedSkillList;
        if (selectedOrgan.length > 0) params.company_ids = selectedOrgan;
        if (salaryLevel.min && salaryLevel.max) {
            params.salary_min = salaryLevel.min;
            params.salary_max = salaryLevel.max;
        }
        if (selectExp !== null) params.experience = selectExp;
        if (searchJob) params.search = searchJob;
    
        config.params = params;
    
        axios.get(jobList(), config)
            .then(res => setJobFilterList(res.data.data.values))
            .catch(err => {
                console.error("Failed to fetch job list:", err);
                // Optionally show a user-friendly error message here
            });
    };
    
    useEffect(() => {
        reloadFilteredJobList();
    }, [selectedEmpId, selectedDegreeId, selectedSkillList, selectedOrgan, salaryLevel, selectExp, searchJob]);
    
    const handleIconClick = (id) => {
        let data = {
            "job_id": id
        };
    
        axios.post(bookMark(), data, config)
            .then(res => {
                setBookMarkCont(res.data.message);
                setSelectedJobId(id);
    
                setTimeout(() => {
                    setBookMarkCont('');
                    setSelectedJobId(null);
                }, 1000);
            })
            .then(() => {
                reloadFilteredJobList(); 
            })
            .catch(err => console.log("Error while bookmarking or reloading job list:", err));
    };

    useEffect(()=>{
        axios.get(teamList(), config)
        .then(res => {
            setTeamName(res.data.data);
        })
        .catch(err => {
            console.log(err);
        });
    },[])
    

    //pagination
    const lastPostIndex = currentPage * perPage;
    const firstPostIndex = lastPostIndex - perPage;
    const currentPosts = jobFilterList.slice(firstPostIndex, lastPostIndex);

    const pageNum = [];
    for (let i = 1; i <= Math.ceil(jobFilterList.length / perPage); i++) {
        pageNum.push(i);
    }    

    const handleAllowUser = (id)=>{
        applyJobNav(`/jobdetails?job_id=${id}`);
    }

    return (
        <>
            <section className="page-title bg-white">
                <div className="auto-container">
                    <div className="title-outer">

                    </div>
                </div>
            </section>
            <section className="ls-section bg-fafbfc">
                <div className="auto-container">
                    <div className="filters-backdrop"></div>
                    <div className="row">
                        <div className="filters-column col-lg-4 col-md-12 col-sm-12">
                            <div className="inner-column">
                                <div className='filter-clear'>
                                    <span className='filter-text'><img className='mr10' src='/assets/images/icons/sliders-vertical.svg' draggable='false' /> Filter</span><span className='float-right'><a className='filter-clear-btn' href='#'>Clear</a></span>
                                </div>
                                <div className="filters-outer">
                                    <button type="button" className="theme-btn close-filters">X</button>
                                    <div className="filter-block">
                                        <h6>salary</h6>
                                        <Box sx={{ width: 300 }}>
                                            <Slider
                                                getAriaLabel={() => 'Value range'}
                                                value={[salaryLevel.min, salaryLevel.max]}
                                                min={10000}
                                                max={100000}
                                                onChange={handleChange}
                                                valueLabelDisplay="auto"
                                                getAriaValueText={(value) => `${value}`}
                                            />
                                        </Box>
                                    </div>
                                    {/* <div className="filter-block">
                                        <h6>Upload Date</h6>
                                        <div className="form-group">
                                            <select className="chosen-select">
                                                <option>Any Time</option>
                                            </select>
                                        </div>
                                    </div> */}
                                    {/* <div className="filter-block">
                                        <h6>Rating</h6>
                                        <div className="rating display-flex">
                                            <input className='rating-input' type='text' placeholder='4.0' />
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star grey-color"></i>
                                        </div>
                                    </div> */}
                                    <div className="switchbox-outer">
                                        <h6>Job type</h6>
                                        <div className="checkbox-outer margin-top-10">
                                            <ul className="checkboxes square fliter-li">
                                            {employmentList && employmentList.map((empVal, index) => (
                                                <li key={empVal.id}>
                                                    <input id={`empCheckbox-${index}`} type="checkbox" name="check" onClick={()=>handleEmpClick(empVal.id)}/>
                                                    <label htmlFor={`empCheckbox-${index}`}>{empVal.name}</label>
                                                </li>
                                            ))}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="filter-block">
                                        <h6>Experience</h6>
                                        <div className="radio-outer margin-top-10">
                                            {experience && experience.map((expVal) => (
                                                <div key={expVal.id} className="radio-box">
                                                    <input
                                                        type="radio"
                                                        name="radio"
                                                        id={`radio-${expVal.id}`}
                                                        checked={selectExp === expVal.id}
                                                        onChange={() => handleRadioChange(expVal.id)}
                                                    />
                                                    <label htmlFor={`radio-${expVal.id}`}>{expVal.Experience}</label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="switchbox-outer">
                                        <h6>Organization</h6>
                                        <div className="checkbox-outer margin-top-10">
                                            <ul className="checkboxes square">
                                                {organ && organ.map((organVal, index) => (
                                                    <li key={organVal.id}>
                                                        <input id={`organCheckbox-${index}`} type="checkbox" name="check" onClick={()=>handleOrganClick(organVal.id)}/>
                                                        <label htmlFor={`organCheckbox-${index}`}>{organVal.name}</label>
                                                    </li>
                                                ))}
                                                {/* <li>
                                                    <input id="orga-a" type="checkbox" name="orga" />
                                                    <label for="orga-a">Faith hub</label>
                                                </li>
                                                <li>
                                                    <input id="orga-b" type="checkbox" name="orga" />
                                                    <label for="orgab">EV-Market</label>
                                                </li>
                                                <li>
                                                    <input id="orga-c" type="checkbox" name="orga" />
                                                    <label for="orga-c">EV-Buyer</label>
                                                </li>
                                                <li>
                                                    <input id="orga-d" type="checkbox" name="orga" />
                                                    <label for="orga-d">EV-Ride</label>
                                                </li> */}
                                            </ul>
                                            {/* <MorepopoverLinks/> */}
                                        </div>
                                    </div>
                                    <div className="switchbox-outer">
                                        <h6>Degree</h6>
                                        <div className="checkbox-outer margin-top-10">
                                            <ul className="checkboxes square">
                                                {degreeList && degreeList.map((degreeVal, index)=>(
                                                    <li key={degreeVal.id}>
                                                        <input id={`degreeCheckbox-${index}`} type="checkbox" name="check" onClick={()=>handleDegreeClick(degreeVal.id)}/>
                                                        <label htmlFor={`degreeCheckbox-${index}`}>{degreeVal.name}</label>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="switchbox-outer">
                                        <h6>Skills</h6>
                                        <div className="checkbox-outer margin-top-10 mb-0">
                                            <ul className="checkboxes square">
                                                {skillList && skillList.map((skillVal, index)=>(
                                                        <li key={skillVal.id}>
                                                            <input id={`skillCheckbox-${index}`} type="checkbox" name="check" onClick={()=>handleSkillClick(skillVal.id)}/>
                                                            <label htmlFor={`skillCheckbox-${index}`}>{skillVal.name}</label>
                                                        </li>
                                                    ))}
                                            </ul>
                                            {/* <MoreSkilsPopoverLinks/> */}
                                        </div>
                                    </div>
                                    <div className="filter-block">
                                        <h6>Teams</h6>
                                        <div className="radio-outer margin-top-10">
                                            {teamName && teamName.map((teamVal) => (
                                                <div key={teamVal.id} className="radio-box">
                                                    <input
                                                        type="radio"
                                                        name="radio"
                                                        id={`radio-${teamVal.id}`}
                                                        // checked={selectExp === teamVal.id}
                                                        // onChange={() => handleRadioChange(teamVal.id)}
                                                    />
                                                    <label htmlFor={`radio-${teamVal.id}`}>{teamVal.name}</label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Filter column end */}
                        </div>
                        <div className="content-column col-lg-8 col-md-12 col-sm-12">
                            <div className='job-search-filter-card'>
                                <div className="job-search-form mb-0">
                                    <div className="row">
                                        <div className="form-group col-lg-3 col-md-12 col-sm-12 border-top border-bottom border-left white-bg"> <span className="icon flaticon-search-1"></span>
                                            <input className='pb-3' type="text" name="field_name" placeholder="Job Search" value={searchJob} onChange={(e)=>{setSearchJob(e.target.value)}}/>
                                        </div>
                                        <div className="form-group col-lg-4 col-md-12 col-sm-12 location border-top border-bottom border-right"> <span className="icon flaticon-map-locator"></span>
                                            <input className='pb-3' type="text" name="field_name" placeholder="Location" />
                                            <span className='remote-input-box'><input type='checkbox' /> <label>Remote Eligible</label></span>
                                        </div>
                                        <div className="form-group col-lg-3 col-md-12 col-sm-12 location border-top border-bottom"> <span className="icon flaticon-briefcase"></span>
                                            {/* <select className="chosen-select">
                                                <option value="">Experience</option>
                                            </select> */}
                                            <Select
                                                options={experienceOptions}
                                                value={selectedExperience}
                                                onChange={handleSelectChange}
                                                placeholder="Experience"
                                                // className="chosen-select border-top border-bottom h40 pl50"
                                            />
                                        </div>
                                        <div className="form-group col-lg-2 col-md-12 col-sm-12 text-right pl-0 pr-0">
                                            <button type="submit" className="theme-btn btn-find-job filter-job">Find Jobs</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='job-search-card'>
                                <div className="ls-outer">
                                    <button type="button" className="theme-btn btn-style-two toggle-filters">Show Filters</button>
                                    {/* <div className="ls-switcher">
                                        <div className="showing-result">
                                            <div className="text">Showing <strong>1-10</strong> of <strong>10</strong> jobs</div>
                                        </div>
                                    </div> */}
                                    {currentPosts && currentPosts.length > 0 ? (
                                    currentPosts.map((jobList) => (
                                        <div className="job-block" key={jobList.id}>
                                        <div className="inner-box">
                                            <div className="content">
                                            <span className="company-logo">
                                                <img
                                                src="/assets/images/jobs/company-logo.svg"
                                                draggable="false"
                                                alt={`${jobList.company_detail.name} Logo`}
                                                />
                                            </span>
                                            <h4>
                                                <a onClick={() => handleAllowUser(jobList.id)}>{jobList.name}</a>
                                            </h4>
                                            <h5>
                                                {jobList.company_detail.name}, {jobList.company_detail.location}
                                            </h5>
                                            <p className="filter-fresh">
                                                3 Days ago
                                                <span className="color-blue">124 Applicants</span>
                                                <span className="float-right">
                                                <strong className="fz16">{jobList.salary_range}</strong>/month
                                                </span>
                                            </p>
                                            <div className="filter-job-dis">
                                                <p className="fz16 jobDescription">{jobList.description}</p>
                                            </div>
                                            <div className="bookmark-btn">
                                                <div className="tags">
                                                <div
                                                    className="bookMark"
                                                    onClick={() => handleIconClick(jobList.id)}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    {jobList.is_bookmark === 0 ? (
                                                    <BsBookmarkDash className="flaticon-bookmark" />
                                                    ) : (
                                                    <BsBookmarkDashFill className="flaticon-bookmark" />
                                                    )}
                                                    {selectedJobId === jobList.id && (
                                                    <p className="bookMarkCont">{bookMarkCont}</p>
                                                    )}
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    ))
                                    ) : (
                                    <h6>No jobs found</h6>
                                    )}

                                </div>
                            </div>
                            <div className='paginationBtns'>
                                {pageNum.length > 1 && pageNum.map((page) => (
                                <button
                                    key={page}
                                    onClick={() => setCurrentPage(page)}
                                    style={{width:25, height:25, border:'1px solid #02CD8D', display:'flex', justifyContent:'center', background: page == currentPage ? '#02CD8D' : 'transparent', color: page == currentPage ? '#fff' : '#000', cursor:'pointer'}}
                                >
                                    {page}
                                </button>
                            ))}
                            </div>
                        </div>
                        {/* Row End  */}
                    </div>
                </div>
            </section>
        </>
    );
}
export default JobsFilter;