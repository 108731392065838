
import React, { useRef, useState } from 'react'
// import { GoogleMap, LoadScript, Marker, StandaloneSearchBox } from '@react-google-maps/api';

  const containerStyle = {
    width: '100%',
    height: '90vh',
  };
  
  const defaultCenter = {
    lat: 37.7749,
    lng: -122.4194,
  };

const Locations = () => {

    const [mapCenter, setMapCenter] = useState(defaultCenter);
    const [markerPosition, setMarkerPosition] = useState(defaultCenter);
    const searchBoxRef = useRef(null);

    const onPlacesChanged = () => {
        const places = searchBoxRef.current.getPlaces();
        if (places && places.length > 0) {
        const location = places[0].geometry.location;
        const newCenter = {
            lat: location.lat(),
            lng: location.lng(),
        };
        setMapCenter(newCenter);
        setMarkerPosition(newCenter);
        }
    };

    return (
        <>
            <section className="page-title style-two mt70 bg-fafbfc">
                <div className="auto-container">
                    <div className="sec-title align-center mb-0"><h3 className="fz32">Our Locations</h3><div className="text">Explore job opportunities across our multiple locations. Find the  perfect position near you and start your career journey with us.</div></div>
                </div>
            </section>
            <section className="contact-section pt-0">
                <div className="auto-container">
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            <div className='map-tab'>
                                <ul>
                                    <li><a href='#'>Africa</a></li>
                                    <li><a href='#'>Latin America</a></li>
                                    <li><a href='#'>Europe</a></li>
                                    <li><a href='#'>Middle East</a></li>
                                    <li><a href='#'>Asia / Oceania</a></li>
                                </ul>
                            </div>
                        </div>
                        {/* <div className='col-lg-2 col-md- col-sm-12'>
                            <div style={{ marginBottom: '10px' }}>
                                <StandaloneSearchBox
                                onLoad={(ref) => (searchBoxRef.current = ref)}
                                onPlacesChanged={onPlacesChanged}
                                >
                                <input
                                    type="text"
                                    placeholder="Search for a location"
                                    style={{
                                    boxSizing: 'border-box',
                                    border: '1px solid transparent',
                                    width: '97%',
                                    height: '39px',
                                    marginTop: '10px',
                                    padding: '2px 12px',
                                    borderRadius: '3px',
                                    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                                    fontSize: '16px',
                                    outline: 'none',
                                    textOverflow: 'ellipses',
                                    }}
                                />
                                </StandaloneSearchBox>
                            </div>
                        </div>
                        <div className='col-lg-10 col-md-10 col-sm-12'>
                            <LoadScript googleMapsApiKey="AIzaSyCHL9B7SsWMevCX-a6ErnaoHl5chULmp5A" libraries={['places']}>
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={mapCenter}
                                zoom={12}
                            >
                                <Marker position={markerPosition} />
                            </GoogleMap>
                            </LoadScript>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    );
}
export default Locations