import React, { useEffect, useState } from 'react';

export function base() {
  return process.env.REACT_APP_API_URL + '/api/v1.0/web/careers/user/';
}

function corebase() {
  return process.env.REACT_APP_API_URL + '/api/v1.0/app/core/';
}

export function getmcc() {
  return corebase() + 'general/mcc/list/all';
}

export function countryCore() {
  return corebase() + 'general/country/list/all';
}

export function stateCore() {
  return corebase() + 'general/state/list/all';
}

export function cityCore() {
  return corebase() + 'general/city/list/all';
}

// Options start
export function getLanguages() {
  return corebase() + 'user/language/list/all';
}
// Options end

// Profile API start
export function profile() {
  return corebase() + 'user/profile';
}
// Profile API end

// API's start
export function getDetails() {
  return base() + "detail/get";
}

export function detailSpecifyAdd() {
  return base() + "detail/specify/add";
}

export function teamList() {
  return base() + "/teams";
}

export function jobList() {
  return base() + "jobs";
}

export function detailAdd() {
  return base() + "detail/add";
}

export function jobApply() {
  return base() + "job/apply";
}

export function employment() {
  return base() + "employment/list/get";
}

export function degree() {
  return base() + "education/list/get";
}

export function skill() {
  return base() + "skill/list/get";
}

export function organization() {
  return base() + "company/list/get";
}

export function bookMark() {
  return base() + "job/bookmark";
}

export function jobGet() {
  return base() + "job/detail";
}
// API's end

export function Configuration() {
  const [loginId, setLoginId] = useState('');

  useEffect(() => {
    const globalValue = window.globalValue || '';
    setLoginId(globalValue);
  }, []);

//   dev -----------

  function getAuthIdFromUrl() {
    const path = window.location.pathname; 
    const parts = path.split('/'); 
    const userId = parts[2]; 
    return userId ? userId : 0; 
  }

  const headers = { 'Accept': 'application/json', "X-Authuser": getAuthIdFromUrl() };

  if (loginId) {
    headers["X-Authuser"] = loginId;
  }

  return {
    headers: headers,
    withCredentials: true,
  };
}
export let redirect_url = 'https://careers.dev.evzone.app/';

// local ------------
// export let redirect_url = 'http://localhost:3000/';
//  return  {
    //     headers: { 'Accept': 'application/json','Content-Type': 'application/json', "X-Authuser" : getAuthIdFromUrl(), "X-Request-Agent":"APP", "X-SID":"sid_1gfr7fRWYkXZmtSF8nYPVV", "X-MUID":"mut_M4QjafyQEhGrvRbUk7OWXWBy7" }
    //  };   //, "X-MUID":"mut_M4QjafyQEhGrvRbUk7OWXWBy7" 

const Url = () => {
  return (
    <div>
    </div>
  );
};

export default Url;
