import React, { useEffect, useState } from 'react';
import { Configuration, teamList} from '../Api/Url';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Teams = () => {
    const config = Configuration();
    const [loading, setLoading] = useState(true); 
    const [teamLists, setTeamLists] = useState('');
    const [loginId, setLoginId] = useState('');

    const nav = useNavigate()

    useEffect(()=>{
        axios.get(teamList(), config)
        .then(res=>{
            console.log('res',res);
            setTeamLists(res.data.data)
            setLoading(false)
        })
        .catch(err=>{
            console.log(err)
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        const globalValue = window.globalValue;
        setLoginId(globalValue);
      }, [window.globalValue]);

    const handleAllowUser=(team_id)=>{
        if(loginId){
            nav(`/u/${loginId}/jobs?team_id=${team_id}`);
        }
        else{
            nav(`/jobs?team_id=${team_id}`);
        }
    }
    
    const expectedLength = teamLists.length > 0 ? teamLists.length : 9;

    return (
        <>
            <div className="sidebar-page-container mt20">
                <div className="auto-container">
                    <div className="align-items-center justify-content-between wow fadeInUp">
                        <div className="sec-title align-center">
                            <h3 className='fz32'>Teams</h3>
                            <div className="text">Explore a wide range of job categories tailored to your skills and interests. Find the perfect opportunity that matches your career goals.</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="content-side col-lg-12 col-md-12 col-sm-12">
                            <div className="blog-grid">
                                <div className="row">
                                    {loading ? (
                                        // Display loading state
                                        Array.from({  length: expectedLength }).map((_, index) => (
                                            <div key={index} className="animated-bg news-block col-lg-4 col-md-6 col-sm-12">
                                                <div className="inner-boxLoading animated-bg">
                                                    <div className="image-box">
                                                        <figure className="image loading-image"></figure>
                                                    </div>
                                                    <div style={{display:'flex', justifyContent:'space-between'}}>
                                                        <h3 className='loading-content'></h3>
                                                        <a className='loading-content'></a>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        teamLists && teamLists.map(value => (
                                            <div key={value.id} className="news-block col-lg-4 col-md-6 col-sm-12">
                                                <div className="inner-box">
                                                    <div className="image-box">
                                                        <figure className="image">
                                                            <img src="/assets/images/teams/team01.png" alt="" draggable='false' />
                                                        </figure>
                                                    </div>
                                                    <div className="lower-content space-between display-flex">
                                                        <h3 className='mb-0'>{value.name}</h3>
                                                        <a onClick={()=>{handleAllowUser(value.id)}}>View Jobs</a>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Teams;
